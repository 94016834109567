#form-contacto 
{
   background-color: #013648; padding: 50px 0; 
}

#form-contacto .section-subheading 
{
   color: white;
}

#form-contacto .info-lugar 
{ 
   font-family: "Montserrat" 
}

#form-contacto .info-lugar .name
{
   color: white; font-weight: 700; font-size:16px; margin-bottom: 20px 
}

#form-contacto .info-lugar .direccion ,
#form-contacto .info-lugar .direccion a
{ 
   color: white; font-size: 16px; font-weight: 100
}

#form-contacto .form-control {
  background-color: transparent;
  border: 1px solid #0d5977;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  font-size: 16px;
  padding-top: 8px !important;
  padding-bottom: 6px !important;
  color: white;
}

#form-contacto ::-webkit-input-placeholder {
   color: #86adbd;
}

#form-contacto :-moz-placeholder { /* Firefox 18- */
   color: #86adbd;  
}

#form-contacto ::-moz-placeholder {  /* Firefox 19+ */
   color: #86adbd;  
}


@media (max-width: 768px) {
   #form-contacto form {
     margin: 15px;
     text-align: center;
   }
}


@media (max-width: 900px) {

   #form-contacto .btn-primary {
      float: none !important; 
    }
}

#map{
   min-height: 450px;
   position: relative; 
   overflow: hidden;
   background-color: #013648; 
   padding: rem !important;
}

#contacto{
   background-color: #013648; 
}
