@media (min-width: 768px) {

    section {
      min-height: 100%;
    } 

}


.section-heading {
    margin-top: 0;
    font-family: "Montserrat";
    color: #FF407A;
    font-size: 15px;
    font-weight: 400;
}

.section-subheading {
    font-family: "Montserrat";
    color: #03344A;
    font-size: 30px;
    font-weight: 800;
    margin: 0 0 10px 0;
    letter-spacing: -1px;
}

.text-highlight {
    font-family: "Montserrat";
    color: #19AFD8;
    font-size: 18px;
    line-height: 20px;
    font-weight: 400;
}

.service-box {
    max-width: 400px;
    margin: 50px auto 0;
}

@media (min-width: 992px) {
    .service-box {
        margin: 20px auto 0;
    }
}

.service-box p {
    margin-bottom: 0;
}

.btn-default {
    color: #222222;
    background-color: white;
    border-color: white;
    -webkit-transition: all 0.35s;
    -moz-transition: all 0.35s;
    transition: all 0.35s;
}

.btn-default:hover,
.btn-default:focus,
.btn-default.focus,
.btn-default:active,
.btn-default.active,
.open > .dropdown-toggle.btn-default {
    color: #222222;
    background-color: #f2f2f2;
    border-color: #ededed;
}
    
.btn-default:active,
.btn-default.active,
.open > .dropdown-toggle.btn-default {
    background-image: none;
}

.btn-default.disabled,
.btn-default[disabled],
fieldset[disabled] .btn-default,
.btn-default.disabled:hover,
.btn-default[disabled]:hover,
fieldset[disabled] .btn-default:hover,
.btn-default.disabled:focus,
.btn-default[disabled]:focus,
fieldset[disabled] .btn-default:focus,
.btn-default.disabled.focus,
.btn-default[disabled].focus,
fieldset[disabled] .btn-default.focus,
.btn-default.disabled:active,
.btn-default[disabled]:active,
fieldset[disabled] .btn-default:active,
.btn-default.disabled.active,
.btn-default[disabled].active,
fieldset[disabled] .btn-default.active {
    background-color: white;
    border-color: white;
}

.btn-default .badge {
    color: white;
    background-color: #222222;
}

.btn-primary {
    font-family: "Montserrat";
    font-size: 11px;
    font-weight: 700;
    color: white;
    background-color: #FE3070;
    border-color: #FE3070;
    -webkit-transition: all 0.35s;
    -moz-transition: all 0.35s;
    transition: all 0.35s;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary.focus,
.btn-primary:active,
.btn-primary.active,
.open > .dropdown-toggle.btn-primary {
    color: white;
    background-color: #e82662;
    border-color: #e82662;
}

.btn-primary:active,
.btn-primary.active,
.open > .dropdown-toggle.btn-primary {
    background-image: none;
}

.btn-primary.disabled,
.btn-primary[disabled],
fieldset[disabled] .btn-primary,
.btn-primary.disabled:hover,
.btn-primary[disabled]:hover,
fieldset[disabled] .btn-primary:hover,
.btn-primary.disabled:focus,
.btn-primary[disabled]:focus,
fieldset[disabled] .btn-primary:focus,
.btn-primary.disabled.focus,
.btn-primary[disabled].focus,
fieldset[disabled] .btn-primary.focus,
.btn-primary.disabled:active,
.btn-primary[disabled]:active,
fieldset[disabled] .btn-primary:active,
.btn-primary.disabled.active,
.btn-primary[disabled].active,
fieldset[disabled] .btn-primary.active {
    background-color: #F05F40;
    border-color: #F05F40;
}
   
.btn-primary .badge {
    color: #F05F40;
    background-color: white;
}

.btn {
    border: none;
    border-radius: 300px;
    font-size: 13px;
}

.btn-xl {
    padding: 14px 45px;
}

@media (max-width: 900px) {
    section {
        padding: 50px 0;
    }

    .logo-top {
        width: 180px !important;
    }
}
/*
.Segments {
    /*
    height: 100%;
    width: 100%;
    * /
    flex-direction: column;
}
*/
section {
    height: 100vh;
} 
