footer {
  background-color: #002A38;
  color: #0599CC;
  padding: 50px 0;
}

footer a,
footer a:hover,
footer a:active {
  color: #0599CC;
}

footer .footer-name {
  margin-left: 10px;
  font-weight: bold;
  margin-top: -2px;
  font-size: 14px;
}

footer .company-name {
  font-size: 16px;
}

footer .social-container {
  border: 2px solid #0599CC;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  width: 35px;
  padding: 0 6px;
  text-align: center;
  display: inline-block;
  font-size: 22px;
  margin-left: 3px;
}

@media (max-width: 900px) {

  footer {
    padding: 25px 0 !important;
    text-align: center;
  }

  footer img,
  footer div,
  footer .pull-left, 
  footer .pull-right {
    float: none !important;
  }

  footer img {
    margin-bottom: 10px;
  }
}