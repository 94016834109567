html,
body {
  height: 100%;
  width: 100%;
  margin: 0;
}

body {
  font-family: 'Merriweather', 'Helvetica Neue', Arial, sans-serif;
}
hr {
  border-color: #F05F40;
  border-width: 3px;
  max-width: 50px;
}
hr.light {
  border-color: white;
}
a {
  -webkit-transition: all 0.35s;
  -moz-transition: all 0.35s;
  transition: all 0.35s;
  color: #F05F40;
}
a:hover,
a:focus {
  color: #eb3812;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Montserrat', 'Helvetica Neue', Arial, sans-serif;
}
p {
  font-size: 16px;
  line-height: 1;
  margin-bottom: 20px;
}
.bg-dark {
  background-color: #222222;
  color: white;
}
.text-faded {
  color: #9c9c9c;
  font-size: 14px;
  line-height: 20px;
}
section {
  padding: 11% 0;
}
aside {
  padding: 50px 0;
}
.no-padding {
  padding: 0;
}

::-moz-selection {
  color: white;
  text-shadow: none;
  background: #222222;
}
::selection {
  color: white;
  text-shadow: none;
  background: #222222;
}
img::selection {
  color: white;
  background: transparent;
}
img::-moz-selection {
  color: white;
  background: transparent;
}

* {
  -webkit-box-sizing:border-box;
  -moz-box-sizing:border-box;
  box-sizing:border-box
 }
/*
body {
  webkit-tap-highlight-color: #222222;
}
*/

/*
NO RECLAMADO
*/
.call-to-action h2 {
  margin: 0 auto 20px;
}
.text-primary {
  color: #F05F40;
}
.no-gutter > [class*='col-'] {
  padding-right: 0;
  padding-left: 0;
}
/*

#producto {
	background-color:#003648;
	padding-top: 54px;
	padding-bottom: 0;
}

#producto .foto_producto_01 {
	background: #003648 url("../images/img_ejemplo_producto.jpg") top left no-repeat;
  background-size: cover;
	text-align:right;
	padding:0;
	min-height: 300px;
}

#producto .foto_producto_01 img {
	display: inline;
}
	
#producto .texto {
	padding: 6% 4% 0 4%;
}

#producto .texto .sr-text-2b  {
	color:#FFF;
}


*/

/*
HASTA AQUI
*/