
#que-hacemos {
  background: #013648 url("../images/img_background_que-hacemos.jpg") top left no-repeat;
  background-size: cover;
}
  
#que-hacemos .section-subheading { 
  color: white; 
}

#que-hacemos .text-faded {
  color: rgba(255, 255, 255, 0.66); 
}

/*
#que-hacemos .row { 
  position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  
}*/

@media (max-width: 1200px) { 
  #que-hacemos {
    background-image: none !important;
  }
}

@media (min-width: 768px) {
  #que-hacemos .texto {
    padding-right: 0%;
    padding-left: 10%;
  }
}
