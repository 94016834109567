
.navbar-default {
  background-color: transparent;
  font-family: "Montserrat";
  -webkit-transition: all 0.35s;
  -moz-transition: all 0.35s;
  transition: all 0.35s;
  color: white;
}

.navbar-default .navbar-brand {
  color: #F05F40;
  font-family: "Montserrat";
  font-weight: 700;
  text-transform: uppercase;
}

.navbar-default .navbar-brand:hover,
.navbar-default .navbar-brand:focus {
  color: #eb3812;
}

.navbar-default  .navbar-toggle {
  font-weight: 700;
  font-size: 12px;
  color: #222222;
  text-transform: uppercase;
}

.navbar-default .nav > li > a,
.navbar-default .nav > li > a:focus {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 13px;
  color: #222222;
}

.navbar-default .nav > li > a:hover,
.navbar-default .nav > li > a:focus:hover {
  color: #FF407A;
}

.navbar-default .nav > li.active > a,
.navbar-default .nav > li.active > a:focus {
  color: #FF407A !important;
  background-color: transparent;
}

.navbar-default .nav > li.active > a:hover,
.navbar-default .nav > li.active > a:focus:hover {
  background-color: transparent;
}

@media (min-width: 768px) {

  .navbar-right {margin-right: 0px;}
    
  .navbar-default {
    background-color: transparent;
    border: 1px solid transparent;
    color: white;
  }

  .navbar-default .navbar-brand {
    color: rgba(255, 255, 255, 0.7);
  }

  .navbar-default .navbar-brand:hover,
  .navbar-default .navbar-brand:focus {
    color: white;
  }

  .navbar-default .nav > li > a,
  .navbar-default .nav > li > a:focus {
    color: white;
  }

  .navbar-default .nav > li > a:hover,
  .navbar-default .nav > li > a:focus:hover {
    color: rgba(255, 255, 255, 0.7);
  }

  .navbar-default.affix {
    background-color: white;
    border-bottom: 2px solid #D7D7D7;
  }

  .navbar-default.affix .navbar-brand {
    color: #F05F40;
    font-size: 14px;
  }

  .navbar-default.affix .navbar-brand:hover,
  .navbar-default.affix .navbar-brand:focus {
    color: #eb3812;
  }

  .navbar-default.affix .nav > li > a,
  .navbar-default.affix .nav > li > a:focus {
    color: #01A1D4;
  }

  .navbar-default.affix .nav > li > a:hover,
  .navbar-default.affix .nav > li > a:focus:hover {
    color: #FF407A;
  }
  
}

.navbar-default.affix .logo-top {
  background-image: url("../images/logo-top.png");
}

.navbar-nav>li>a {
  padding-top: 18px;
  padding-bottom: 21px;
  font-family: "Montserrat";
  font-size: 14px !important;
}

@media (min-width: 768px) {
  .navbar-default.affix .logo-top {
    background-image: url("../images/logo-top.png") !important;
  }

  .logo-top {
    background-image: url("../images/logo-top-blanco.png") !important;
  }
}

@media (max-width: 768px) {
  .navbar-nav>li>a {
    padding: 11px 0 11px 22px;
  }
  
}

@media (max-width: 900px) {
  .logo-top {
    width: 180px !important;
  } 
}

.logo-top {
  width: 375px;
  height: 50px;
  background-image: url("../images/logo-top-blanco.png");
  background-repeat: no-repeat;
  background-size: 375px 50px;
}

.affix {
  position: fixed;
  top: 0;
  right: 0px;
  left: 0;
  z-index: 1030;

}

.affix.navbar {
  background-color: white;
  border-bottom: 2px solid #D7D7D7;
  color: #fff;
}

.affix.navbar .nav-item > a, 
.affix.navbar .navbar-brand {

  background-color: transparent;
  border: 1px solid transparent;
  
  color:  #01A1D4;


}

@media (min-width: 768px) {
  .navbar-brand .logo-top {
    background-image: url("../images/logo-top-blanco.png") !important;
  }

}

.navbar-light .navbar-nav .nav-link{
  color: #fff;

}