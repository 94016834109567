
header {
  position: relative;
  width: 100%;
  min-height: auto;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  background-size: cover;
  -o-background-size: cover;
  background-position: center;
  background-image: url("../images/header.jpg");
  text-align: center;
  color: white;
  top: -80px;
  z-index: -5;
}

header .header-content {
  position: relative;
  text-align: center;
  padding: 100px 15px 100px;
  width: 100%;
}

header .header-content .header-content-inner {
  margin-top: 75px;
}

header .header-content .header-content-inner h1 {
  font-weight: 700;
  text-transform: uppercase;
  margin-top: 29px;
  margin-bottom: 0px;
  font-size: 30px;
}

header .header-content .header-content-inner hr {
  margin: 30px auto;
}

header .header-content .header-content-inner p {
  font-weight: 300;
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
  margin-bottom: 30px;
}

@media (max-width: 900px) {
  header .header-content {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  header .header-content .header-content-inner {
    margin-top: 50px;
  }

  header .header-content .header-content-inner h1 {
    font-size: 14px;
    line-height: 14px;
	  margin-bottom: 8px;
  }

}

@media (max-width: 768px) {
  header .header-content .header-content-inner img {
      width: 195px;
  }
}


@media (min-width: 768px) {
  
  header {
    min-height: 100%;
  }

  header .header-content {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    padding: 0 50px;
  }
 
  header .header-content .header-content-inner {
    max-width: 815px;
    margin-left: auto;
    margin-right: auto;
  }

  header .header-content .header-content-inner h1 {
    font-size: 20px;
    line-height: 20px;
    margin-bottom: 7px;
  }

  header .header-content .header-content-inner p {
    font-size: 17px;
    line-height: 18px;
    max-width: 60%;
    margin-left: auto;
    margin-right: auto;
  }

}

