#solucion-a-medida {
    background: url("../images/img_background_solucion.png") top right no-repeat;
    background-size: cover;
    background-position: 0px 0px;
  }

@media (min-width: 768px) {
	#solucion-a-medida .texto , #que-hacemos .texto {
		padding-right: 5%;
		padding-left: 5%;
	}

}

@media (max-width: 1200px) { 

  #solucion-a-medida {
    background-image: none !important;
  }
}
